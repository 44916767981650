


import React, { useState } from 'react';

import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const WallConfigurator = () => {
    const [formData, setFormData] = useState({
        wallWidth: '',
        wallHeight:'',
        type: 'CLT-280-C7s',
        door1X: '', //
        door1Y: '',
        door1Width: '',
        door1Height: '',

        door2X: '', //
        door2Y: '',
        door2Width: '',
        door2Height: '',

        door3X: '', //
        door3Y: '',
        door3Width: '',
        door3Height: '',


        window3X: '', //
        window3Y: '', //
        window3width: '', //
        window3height: '', //
 
        window2X: '', //
        window2Y: '', //
        window2width: '', //
        window2height: '', //

        window1X: '', //
        window1Y: '', //
        window1width: '', //
        window1height: '', //

        socket1: false,
        socket1X: '',
        socket1Y:'',


        socket2: false,
        socket2X: '',
        socket2Y:'',


        socket3: false,
        socket3X: '',
        socket3Y:'',


        deur1: false,        deur2: false,        deur3: false,
        raam1: false, raam2: false, raam3: false,
    });

    const [url,setUrl] = useState('');
    const [status,setStatus] = useState('INITIAL'); // INITIAL , LOADING , DONE

    const walltypes = [
        {name: 'CLT-280-C7s', CODE:'CLT-280-C7s' , LIBRARY: "CLT-WALLS_BNL"},
        {name: 'EXT.CC-300', CODE:'EXT.CC-300' , LIBRARY: "V3_WALLARCH_BNL"},
    ];

    const handleSubmit = async (e) => {
        setStatus('LOADING');
        e.preventDefault();

        
        

        const requestBody = {
            name: "WS" + Math.floor(Math.random()*999999), 
            model : "Wandshop_06",
            parameters: [
                {
                    name: "Lengte_wand",
                    value: formData.wallWidth
                }
            ],
objects: [

    {object:"wall_type",name: "wall_type", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, CODE: walltypes.find(x => x.name == formData.type).CODE, LIBRARY: walltypes.find(x => x.name == formData.type).LIBRARY},    
    {object:"wall",name: "set_wall", parameters: {"TEST":500}, DX:0,DY:0, DZ:0, "NEWPARAMS":`-|-||IN|0|${formData.wallHeight}`},
    {object:"door_01", name: ( formData.deur1? "set_door_01" : "remove_door_01"), parameters: {ROW: formData.door1Width,ROH:formData.door1Height}, DX:formData.door1X,DY:0, DZ:formData.door1Y},
    {object:"door_02", name: ( formData.deur2? "set_door_02" : "remove_door_02"), parameters: {ROW: formData.door2Width,ROH:formData.door2Height}, DX:formData.door2X,DY:0, DZ:formData.door2Y},
    {object:"door_03", name: ( formData.deur3? "set_door_03" : "remove_door_03"), parameters: {ROW: formData.door3Width,ROH:formData.door3Height}, DX:formData.door3X,DY:0, DZ:formData.door3Y},


    {object:"window_01", name: ( formData.raam1? "set_window_01" : "remove_window_01"), parameters: {ROW: formData.window1width,ROH:formData.window1height}, DX:formData.window1X,DY:0, DZ:formData.window1Y},
    {object:"window_02", name: ( formData.raam2? "set_window_02" : "remove_window_02"), parameters: {ROW: formData.window2width,ROH:formData.window2height}, DX:formData.window2X,DY:0, DZ:formData.window2Y},
    {object:"window_03", name: ( formData.raam3? "set_window_03" : "remove_window_03"), parameters: {ROW: formData.window3width,ROH:formData.window3height}, DX:formData.window3X,DY:0, DZ:formData.window3Y},


    {object:"stopcontact_01","name": ( formData.socket1?  "set_stopcontact_01" : "remove_stopcontact_01"), "parameters": {"TEST":500}, DX:formData.socket1X,DY:'', DZ:formData.socket1Y},
    {object:"stopcontact_02","name":  ( formData.socket2? "set_stopcontact_02" : "remove_stopcontact_02" ), "parameters": {"TEST":500},  DX:formData.socket2X,DY:'', DZ:formData.socket2Y},
    {object:"stopcontact_03","name":  ( formData.socket3? "set_stopcontact_03" : "remove_stopcontact_03"), "parameters": {"TEST":500}, DX:formData.socket3X,DY:'', DZ:formData.socket3Y}
]


        };

 
        console.log(requestBody);
        //return;


        try {
            const response = await fetch('https://us-central1-poc-wandshop.cloudfunctions.net/wandshop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                const url = await data.url;
                console.log(url);
                setUrl(url);
                setStatus('DONE')
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData);
    };

    const handleCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        console.log(formData);
    };

    return (

            <div class="container">
            <div class="form-container">
                <h1>Wall Configurator - API Visual Demo</h1>

            <form onSubmit={handleSubmit}>
                <div className="section">
                    <h2>Wall Dimensions</h2>

                    <div className='group'>
                    <div className="form-group">
                        <label htmlFor="wall-width">Width (mm):</label>
                        <input type="number" id="wall-width" name="wallWidth" value={formData.wallWidth} onChange={handleChange} required />
                    </div>
                    
                      
                        <div className="form-group">
                            <label htmlFor="wall-height">Height (mm):</label>
                            <input type="number" id="wall-height" name="wallHeight" value={formData.wallHeight} onChange={handleChange} required />
                        </div>
                     
                    <div className="form-group">
                        <label htmlFor="type">Type:</label>
                        <select id="type" name="type" value={formData.type} onChange={handleChange} required>
                            {walltypes.map(wt => (<option value={wt.CODE}>{wt.name}</option>))}
                            
                        </select>
                    </div> 
                    </div>
                </div>
                <div className="section">



                    <h2>Door 1 <Switch {...label} onChange={handleCheckbox} name='deur1' value={formData.deur1}  /></h2>
                    

                    {formData.deur1 == true? ( <div className='group'>
                    
                    <div className="form-group">
                        <label htmlFor="door1-x">X</label>
                        <input type="number" id="door1-x" name="door1X" value={formData.door1X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="door1-y">Y</label>
                        <input type="number" id="door1-y" name="door1Y" value={formData.door1Y} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="door1-width">Width (mm):</label>
                        <input type="number" id="door1-width" name="door1Width" value={formData.door1Width} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="door1-height">Height (mm):</label>
                        <input type="number" id="door1-height" name="door1Height" value={formData.door1Height} onChange={handleChange} required />
                    </div>
                    </div>
                 ) : ''}
                    
                </div>

                <div className="section">



<h2>Door 2 <Switch {...label} onChange={handleCheckbox} name='deur2' value={formData.deur2}  /></h2>


{formData.deur2 == true? (  <div className='group'>
                    
                    <div className="form-group">
                        <label htmlFor="door2-x">X</label>
                        <input type="number" id="door2-x" name="door2X" value={formData.door2X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="door2-y">Y</label>
                        <input type="number" id="door2-y" name="door2Y" value={formData.door2Y} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="door2-width">Width (mm):</label>
                        <input type="number" id="door2-width" name="door2Width" value={formData.door2Width} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="door2-height">Height (mm):</label>
                        <input type="number" id="door2-height" name="door2Height" value={formData.door2Height} onChange={handleChange} required />
                    </div>
                    </div>
) : ''}

</div>


<div className="section">



<h2>Door 3 <Switch {...label} onChange={handleCheckbox} name='deur3' value={formData.deur3}  /></h2>


{formData.deur3 == true? ( <div className='group'>

<div className="form-group">
    <label htmlFor="door3-x">X</label>
    <input type="number" id="door3-x" name="door3X" value={formData.door3X} onChange={handleChange} required />
</div>

  
<div className="form-group">
    <label htmlFor="door3-y">Y</label>
    <input type="number" id="door3-y" name="door3Y" value={formData.door3Y} onChange={handleChange} required />
</div>
<div className="form-group">
    <label htmlFor="door3-width">Width (mm):</label>
    <input type="number" id="door3-width" name="door3Width" value={formData.door3Width} onChange={handleChange} required />
</div>
<div className="form-group">
    <label htmlFor="door3-height">Height (mm):</label>
    <input type="number" id="door3-height" name="door3Height" value={formData.door3Height} onChange={handleChange} required />
</div>
</div>
) : ''}

</div>


<div className="section">
                    <h2>Window 1  <Switch {...label} onChange={handleCheckbox} name='raam1' value={formData.raam1}  /></h2>
                    {formData.raam1 == true? (  <div className='group'>


                    <div className="form-group">
                        <label htmlFor="window1X">X:</label>
                        <input type="number" id="window1X" name="window1X" value={formData.window1X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="window1Y">Y:</label>
                        <input type="number" id="window1Y" name="window1Y" value={formData.window1Y} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window1width">Width (mm):</label>
                        <input type="number" id="window1width" name="window1width" value={formData.window1width} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window1height">Height (mm):</label>
                        <input type="number" id="window1height" name="window1height" value={formData.window1height} onChange={handleChange} required />
                    </div>


                        </div>
                    ) : ''}

                    </div> <div className="section">
<h2>Window 2  <Switch {...label} onChange={handleCheckbox} name='raam2' value={formData.raam2}  /></h2>

{formData.raam2 == true? (  <div className='group'>

<div className="form-group">
                        <label htmlFor="window2X">X:</label>
                        <input type="number" id="window2X" name="window2X" value={formData.window2X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="window2Y">Y:</label>
                        <input type="number" id="window2Y" name="window2Y" value={formData.window2Y} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window2width">Width (mm):</label>
                        <input type="number" id="window2width" name="window2width" value={formData.window2width} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window2height">Height (mm):</label>
                        <input type="number" id="window2height" name="window2height" value={formData.window2height} onChange={handleChange} required />
                    </div>

                    </div>
                    ) : ''}
</div> <div className="section">
<h2>Window 3  <Switch {...label} onChange={handleCheckbox} name='raam3' value={formData.raam3}  /></h2>

{formData.raam3 == true? (  <div className='group'>

<div className="form-group">
                        <label htmlFor="window3X">X:</label>
                        <input type="number" id="window3X" name="window3X" value={formData.window3X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="window3Y">Y:</label>
                        <input type="number" id="window3Y" name="window3Y" value={formData.window3Y} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window3width">Width (mm):</label>
                        <input type="number" id="window3width" name="window3width" value={formData.window3width} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="window3height">Height (mm):</label>
                        <input type="number" id="window3height" name="window3height" value={formData.window3height} onChange={handleChange} required />
                    </div>


                    </div>
                    ) : ''}

                </div>







                <div className="section">
                    <h2>Socket 1  <Switch {...label} onChange={handleCheckbox} name='socket1' value={formData.socket1}  /></h2>
                    {formData.socket1 == true? (  <div className='group'>


                    <div className="form-group">
                        <label htmlFor="socket1X">X:</label>
                        <input type="number" id="socket1X" name="socket1X" value={formData.socket1X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="socket1Y">Y:</label>
                        <input type="number" id="socket1Y" name="socket1Y" value={formData.socket1Y} onChange={handleChange} required />
                    </div>


                        </div>
                    ) : ''}

                    </div> <div className="section">
<h2>Socket 2  <Switch {...label} onChange={handleCheckbox} name='socket2' value={formData.socket2}  /></h2>

{formData.socket2 == true? (  <div className='group'>

<div className="form-group">
                        <label htmlFor="socket2X">X:</label>
                        <input type="number" id="socket2X" name="socket2X" value={formData.socket2X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="socket2Y">Y:</label>
                        <input type="number" id="socket2Y" name="socket2Y" value={formData.socket2Y} onChange={handleChange} required />
                    </div>
       

                    </div>
                    ) : ''}
</div> <div className="section">
<h2>Socket 3  <Switch {...label} onChange={handleCheckbox} name='socket3' value={formData.socket3}  /></h2>

{formData.socket3 == true? (  <div className='group'>

<div className="form-group">
                        <label htmlFor="socket3X">X:</label>
                        <input type="number" id="socket3X" name="socket3X" value={formData.socket3X} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                        <label htmlFor="socket3Y">Y:</label>
                        <input type="number" id="socket3Y" name="socket3Y" value={formData.socket3Y} onChange={handleChange} required />
                    </div>
        


                    </div>
                    ) : ''}

                </div>

                



                <button disabled={!(status=='INITIAL')} type="submit"> {status == 'LOADING' ? (<div className='buttonloader'><CircularProgress />LOADING...</div>) : 'Call API'} </button><br /><br />

                {url != '' ? <a href={url} >Download project</a> : 'Fill in the form and submit the form... '}


            </form>
        </div>
                

                </div>
    );
};

export default WallConfigurator;
